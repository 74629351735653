import React from "react"


export default function Bottom() {
    return (
        <>
            <section id="our-products1">
                <div className="card">

                    <h3>Call Us at +91 9899949333</h3>

                </div>




            </section>


        </>
    )
}
