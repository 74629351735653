import React from "react"


export default function Hero() {
  return (
    <section className="clearfix" className="container clearfix " className="hero-img container-home" data-aos="">
      <img style={{ width: "-webkit-fill-available", }} src="./assets/img/banner.png" alt="" />

    </section>
  )
}
