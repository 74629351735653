import React from "react"

export default function Services() {
    return (
        <>
            <section id="why-us" className="clearfix">

                <div className="row">
                    <div className="col-md-10">
                        <h3 style={{ color: "white", fontWeight: "500", marginTop: "20px", textAlignLast: "center" }}>Health Care Services right at your <b style={{color: "#e4773c",}}>HOME </b> </h3>
                    </div>
                    
                </div>
                <div  className="team-flex-container" >
        <div className="col-md-4" className="team-flex-item1">
          <img className="img-icon" src="/assets/img/monitor.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available", }}>Home ICU Setup</h5>
        </div>
       
      </div>

      <div className="team-flex-container">
        <div className="col-md-4" className="team-flex-item1">
          <img className="img-icon" src="/assets/img/doctor.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available", }}>Doctor Visit</h5>
        </div>
       
      </div>


      <div className="col-md-4" className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/dentist.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available", }}>Medical Equipments</h5>
        </div>
        
      </div>



      <div className="team-flex-container">
        <div className="col-md-4" className="team-flex-item1">
          <img className="img-icon" src="/assets/img/healthcare-and-medical.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available", }}>Phisiotherapy / Yoga</h5>
        </div>
        
      </div>

      <div className="team-flex-container">
        <div className="col-md-4" className="team-flex-item1">
          <img className="img-icon" src="/assets/img/nurse.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available",}}>Nursing and Patient Care</h5>
        </div>
       
      </div>
      <div className="team-flex-container">
      <div className="col-md-4" className="team-flex-item1">
          <img className="img-icon" src="/assets/img/cardiogram.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available",}}>Invasive Ventilation</h5>
        </div>
        
      </div>
      <div className="team-flex-container">
      <div className="col-md-4" className="team-flex-item1">
          <img className="img-icon" src="/assets/img/band-aid.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available",}}>Tracheostomoy Care</h5>
        </div>
        

      </div>
      <div className="team-flex-container">
      <div className="col-md-4" className="team-flex-item1">
          <img className="img-icon" src="/assets/img/band-aid.png" alt="" />
          <h5 style={{ color: "white", fontWeight: "350", marginLeft: "10px", minWidth: "-webkit-fill-available",}}>Dressing, Injections and Infusions</h5>
        </div>
       
      </div>

            </section>


        </>
    )
}
