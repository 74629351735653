import React from "react"
import Header from '../components/Header'
import Navigation from '../components/Navigation'
// import Footer from '../components/Footer'
import Back from "./Contact"                        
import Bottom from "./Bottom"
import Hero from "./Hero"
import Services from "./Services"
import HomeText from "./HomeText"





export default function Layout() {



  return (
    <>
      <Header />
      <Navigation />
      <Hero/>
      
     
      {/* <Hero/> */}
      <Bottom/>
      <Back />
      <main id="main">
      
      <Services/>
    
      
      <HomeText/>

      </main>


    </>
  )
}