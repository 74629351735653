import React from "react"

export default function Back () {
  return (
    <>
      <section id="why-us" className="clearfix">
       
        <div className="row">
          <div className="col-md-4 info">
          <div className="col-md-4 info">
            <i><img className="img-icon2" src="/assets/img/contact page icon/remcopy.png" alt="" /></i>
            

          </div>
            <br></br>
            <h5 style={{ color: "white", fontWeight: "600" }}>Our Office</h5>
            <p style={{ color: "white", fontWeight: "600" }}>35, 3rd floor link road Lajpat Nagar 3, Delhi- 110024</p>

          </div>
          <div className="col-md-4 info">
          <div className="col-md-4 info">
            <i><img className="img-icon2" src="/assets/img/contact page icon/mobile-phone.png" alt="" /></i>
            
          </div>
            <br></br>
            <h5 style={{ color: "white", fontWeight: "600" }}>Call Us</h5>
            <p style={{ color: "white", fontWeight: "600" }}>+91 9899949333</p>
          </div>
          <div className="col-md-4 info">
          <div className="col-md-4 info">
            <i><img className="img-icon3" src="/assets/img/contact page icon/messagecopy 2.png" alt="" /></i>
            
          </div>
            <br></br>
            <h5 style={{ color: "white", fontWeight: "600" }}>Email Us</h5>
            <p style={{ color: "white", fontWeight: "600" }}>info@unifiedcare.in</p>
          </div>
        </div>
        

      </section>


    </>
  )
}
