import React from "react"


export default function HomeText() {
    return (
        <>
            <section id="our-products" className="clearfix">
                <div className="row">
                    <div className="col-md-10 info">

                        <h3 style={{ color: "black", textAlign: "center", fontWeight: "350" }}> In Home Care Services for everyone. A care plan just for you</h3>
                        
                        <p className="founder-p-text" style={{ textAlign: "left", }}>Our expert team of caregivers provide in-home caregiving facilities to the family mamber of any age or gender. Our home care lets your loved ones enjoy healthy lives in the comfort of your HOME where they feel a sense of familiar environment.Tailored care for every unique situation through a Custom Care Plan is provided after a counselling call.</p>
                    </div>
                </div>



            </section>


        </>
    )
}
